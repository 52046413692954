@import 'bootstrap/bootstrap';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';

@font-face {
	font-family: NexaLight;
	src: url('../fonts/Nexa-Light.eot'); /* IE9 Compat Modes */
	src: url('../fonts/Nexa-Light.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/Nexa-Light.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/Nexa-Light.woff') format('woff'),
		/* Pretty Modern Browsers */ url('../fonts/Nexa-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: Nexa;
	src: url('../fonts/Nexa-Book.eot'); /* IE9 Compat Modes */
	src: url('../fonts/Nexa-Book.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/Nexa-Book.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/Nexa-Book.woff') format('woff'),
		/* Pretty Modern Browsers */ url('../fonts/Nexa-Book.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: NexaBold;
	src: url('../fonts/Nexa-Bold.eot'); /* IE9 Compat Modes */
	src: url('../fonts/Nexa-Bold.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/Nexa-Bold.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/Nexa-Bold.woff') format('woff'),
		/* Pretty Modern Browsers */ url('../fonts/Nexa-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: NexaHeavy;
	src: url('../fonts/Nexa-Heavy.eot'); /* IE9 Compat Modes */
	src: url('../fonts/Nexa-Heavy.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/Nexa-Heavy.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/Nexa-Heavy.woff') format('woff'),
		/* Pretty Modern Browsers */ url('../fonts/Nexa-Heavy.ttf') format('truetype'); /* Safari, Android, iOS */
}

$font-primary: Nexa, 'Segoe UI', Helvetica, Arial, sans-serif;
$primary: #00d664;
$primarydark: #05aa52;
$greenmuted: #2bb088;
$greenhighlight: #c8f68d;
$gradient: linear-gradient(90deg, rgba(43, 176, 136, 1) 0%, rgba(1, 214, 101, 1) 50%, rgba(77, 243, 155, 1) 100%);

::selection {
	background-color: $primary;
	border-radius: 10px;
	color: #fff;
}

::-webkit-scrollbar {
	border-radius: 10px;
	height: 10px;
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;
}
#content::-webkit-scrollbar-track {
	background-color: #f2f2f2;
}
body {
	font-family: $font-primary;
	font-size: 13px;
	line-height: 1.6;
	font-weight: normal;
	background: #000;
}
a {
	transition: 0.3s all ease;
	color: #232323;
	&:hover,
	&:focus {
		color: $primary;
		text-decoration: none !important;
		outline: none !important;
		box-shadow: none;
	}
}
ul {
	list-style-type: none !important;
}
i {
	font-family: 'FontAwesome' !important;
	font-style: normal;
}
button {
	transition: 0.3s all ease;
	&:hover,
	&:focus {
		text-decoration: none !important;
		outline: none !important;
		box-shadow: none !important;
	}
}
h1 {
	font-size: 48px;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
	line-height: 1.5;
	font-weight: 400;
	font-family: $font-primary;
	color: $black;
}

a[data-toggle='collapse'] {
	position: relative;
}

.dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

// #sidebarCollapse{
// 	span{
// 		@include media-breakpoint-down(md){
// 	    display: none;
// 		}
// 	}
// }
.car-vin {
	word-break: break-all;
}
.form-control {
	height: 40px !important;
	background: $white;
	color: $black;
	font-size: 13px;
	border-radius: 4px;
	box-shadow: none !important;
	border: transparent;
	&:focus,
	&:active {
		border-color: $black;
	}
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: rgba(255, 255, 255, 0.5);
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: rgba(255, 255, 255, 0.5);
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: rgba(255, 255, 255, 0.5);
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: rgba(255, 255, 255, 0.5);
	}
}

/* ---------------------------------------------------
	  BASE STYLES
  ----------------------------------------------------- */
.primary-bg {
	background-color: $primary;
}
.light-bg {
	background-color: #f2f2f2;
}
.green {
	color: $primary;
	&.big {
		font-size: 30px;
		line-height: 4px;
		position: relative;
		top: 10px;
	}
}
.darkgreen {
	color: $primarydark;
}
.myborder-right {
	border-right: 1px solid #dadada;
}
.myborder-left {
	border-left: 1px solid #dadada;
}
.myborder-top {
	border-top: 1px solid #dadada;
}
.wrapper {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	min-height: 50vh;
	width: 90%;
	max-width: 1920px;
	margin: 0px auto 260px;
	align-items: stretch;
	perspective: 1500px;
	background-color: #fff;
}
.autohelp-toast {
	padding: 20px;
	min-width: 200px;
	max-width: 90vw;
	height: auto;
	font-family: NexaBold;
	font-size: 16px;
	color: $primarydark;
	// background: linear-gradient(to top, $primary 0%, $primarydark 100%) !important;
	background: #fff;
	border-left: 4px solid $primarydark;
	position: fixed;
	display: block;
	bottom: 40px;
	right: 31px;
	box-shadow: 0 0 200px $greenmuted;
	z-index: 99999;
}
.ah-select {
	position: absolute;
	top: 2.5rem;
	// height: 0;
	overflow: visible;
	z-index: 999;
	background-color: #fff;
	width: 100%;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	border: 1px solid #dadada;
	ul {
		list-style-type: none;
		padding: 0;
		max-height: 300px;
		overflow-y: auto;
		margin: 0;
		li {
			color: #232323;
			cursor: pointer;
			background-color: #fff;
			border-bottom: 1px solid #dadada;
			padding: 10px;
			font-family: Nexabold;
			width: 100%;
			&:hover {
				background: $greenhighlight;
			}
			img {
				max-width: 25px;
				position: relative;
				bottom: 3px;
			}
		}
	}
}
#filters-container {
	&.show {
		display: flex !important;
	}
}
#details-container {
	&.show {
		display: flex !important;
	}
}

#menuCollapse {
	display: block !important;
}

#mobile-filters {
	background: #f2f2f2;
	padding: 20px 0;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	display: none;
	.tab {
		height: 35px;
		width: 35px;
		cursor: pointer;
		outline: none;
		border: 0;
	}
	.filter-tab {
		background: url('/assets/images/icon-filtry.svg') no-repeat center center / cover;
		&.active,
		&[aria-expanded='true'] {
			background: url('/assets/images/icon-filtry-active.svg') no-repeat center center / cover;
		}
	}
	.map-tab {
		background: url('/assets/images/icon-mapa.svg') no-repeat center center / cover;
		&.active,
		&[aria-expanded='true'] {
			background: url('/assets/images/icon-map-active.svg') no-repeat center center / cover;
		}
	}
	.details-tab {
		background: url('/assets/images/icon-details.svg') no-repeat center center / cover;
		&.active,
		&[aria-expanded='true'] {
			background: url('/assets/images/icon-details-active.svg') no-repeat center center / cover;
		}
	}
}
#topbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	.topbar-left {
		display: flex;
		justify-content: flex-start;
		padding-right: 70px;
		align-items: center;
	}
	.top-logo {
		margin: 0px 20px;
		cursor: pointer;
		img {
			height: 45px;
			width: auto;
			display: block;
		}
	}
	.top-menu {
		// display: flex;
		display: none;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 0;
		li {
			margin-right: 30px;
			display: inline-block;
			text-align: center;
			font-family: 'Nexa', sans-serif;
			font-weight: 600;
			cursor: pointer;
		}
	}
	.sidebar-header {
		background-color: $primary;
		justify-self: flex-end;
		height: 120px;
		padding: 0 30px 0px 30px;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: flex-end;
		.hello-user {
			font-size: 28px;
			color: #fff;
			margin-right: 100px;
			margin-bottom: 0;
			span {
				text-decoration: underline;
			}
		}
		// &:hover .logout-container {
		// 	opacity: 1;
		// 	transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
		// }
		// .logout-container {
		// 	opacity: 0;
		// 	position: absolute;
		// 	bottom: -30px;
		// 	z-index: 999;
		// 	display: block;
		// 	padding: 10px 10px 20px 10px;
		// 	background-color: #fff;
		// 	font-weight: 600;
		// 	&:before {
		// 		content: "";
		// 		background: #fff;
		// 		width: 10px;
		// 		height: 10px;
		// 		display: block;
		// 		position: relative;

		// 		top: -15px;
		// 		right: -48%;
		// 		transform: rotate(45deg);
		// 	}
		// }
	}
}

#mobileMenu {
	display: none;
}
.w30 {
	min-width: 30%;
	max-width: 30%;
}
.w40 {
	min-width: 40%;
	max-width: 40%;
}
.w50 {
	min-width: 50%;
	max-width: 50%;
}
.w60 {
	min-width: 60%;
	max-width: 60%;
}
.w70 {
	min-width: 70%;
	max-width: 70%;
}
.w100 {
	min-width: 100%;
	max-width: 100%;
}

/* ---------------------------------------------------
	SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
	min-height: calc(100% - 120px);
	max-height: calc(80vh - 120px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	// margin-top: 190px;
	background: #01d665;
	position: absolute;
	right: 0;
	bottom: 0;
	color: #fff;
	transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
	transform-origin: bottom right;
	transform: rotateY(180deg);
	z-index: 999;
	&.active {
		height: auto;
		min-height: calc(100% - 120px);
		margin: 0;
		transform: none;
	}
	&.with-filters {
		// min-height: calc(100% - 282px) !important;
		// min-height: calc(100% - 120px) !important;
	}
	label {
		letter-spacing: 0.5px;
		color: #fff;
		width: 100%;
	}

	.sidebar-content {
		padding: 20px;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
	}

	.gps-container {
		margin: 10px 0px;
		width: 100% !important;
	}
	.lower-form {
		background-color: $primarydark;
		z-index: 999;
		// max-height:40vh;
		// overflow-y: auto;
		padding: 30px;
		margin: 30px -30px -30px -30px;
		input {
			margin-bottom: 0;
		}
		.invalid-form {
			input {
				margin-bottom: 10px;
			}
			// .invalid-feedback {
			// 	color: #fff !important;
			// }
		}
		.text-white {
			margin: 0;
		}
		button {
			margin-top: 20px;
		}
		.service-selector {
			// margin: 0px -30px;
			width: 100%;
			// display: flex;
			// flex-wrap: wrap;
			#category-level1 {
				// width: 100%;
				// min-width: 100%;
			}
			#category-level2,
			#category-level3 {
				.subcategory-list,
				.subcategory-details {
					max-height: 350px;
				}
				// &:before {
				// 	background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, $primarydark 100%) !important;
				// }
				// &:after {
				// 	background-image: linear-gradient(to top, $primarydark 0%, rgba(255, 255, 255, 0) 100%) !important;
				// }
				li {
					padding: 10px 30px 5px 10px;
					color: #232323 !important;
					&.active {
						background-color: #c8f68d;
					}
					&:hover {
						background-color: #c8f68d;
						cursor: pointer;
						background-color: rgba(255, 255, 255, 0.2);
						i {
							display: block;
							&.fa-times {
								color: #fe5051 !important;
								font-size: 10px;
								cursor: pointer;
							}
							&.fa-plus {
								color: #fff !important;
							}
						}
					}
				}
			}
		}
	}

	.chips-container {
		background-color: transparent;
		#category-level2,
		#category-level3 {
			li {
				color: #232323 !important;
			}
			// &:before {
			// 	background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, $primary 100%) !important;
			// }
			// &:after {
			// 	background-image: linear-gradient(to top, $primary 0%, rgba(255, 255, 255, 0) 100%) !important;
			// }
		}
	}
}

.txt-solid {
	font-family: Nexa;
	color: #232323;
	&.date-container,
	&.category-container,
	&.cat-1level,
	&.cat-2level,
	&.cat-3level,
	&.date-to,
	&.date-from {
		margin-right: 0 !important;
	}
	&.bigger {
		font-size: 22px !important;
		font-family: NexaHeavy;
	}
}
.category-container {
	margin-bottom: 8px;
	flex-wrap: wrap;
}
.bg-car {
	background: #01d665 url(/assets/images/spoiler-bg.jpg) no-repeat bottom right / cover !important;
	background-blend-mode: multiply;
	justify-content: flex-start !important;
}
.bg-car2 {
	background: #01d665 url(/assets/images/lampa-bg.jpg) no-repeat bottom left / contain !important;
	background-blend-mode: multiply;
}
hr {
	width: 100%;
	&.dark {
		border-color: $primarydark !important;
		background-color: $primarydark !important;
		margin: 0 -15px;
	}
	&.darkgray {
		border-color: rgba(0, 0, 0, 0.2) !important;
		background-color: rgba(0, 0, 0, 0.1) !important;
		margin: 0 -15px;
	}
	&.light {
		border-color: $primary !important;
		background-color: $primary !important;
	}
}

/* ---------------------------------------------------
	  LOGIN STYLE
  ----------------------------------------------------- */

.login-cover {
	background-color: $primary;
	position: sticky;
	top: 0;
	.top-logo {
		width: 100%;
		text-align: center;
		img {
			height: 160px;
			width: auto;
			margin: 50px auto;
			display: block;
		}
	}
}
//anim strzalki roleselect
.arrcontainer {
	height: 120px;
	width: 18px;
	// margin: 250px auto 0;
	position: absolute;
	top: 8px;
	transform: scale(0.23) rotate(-90deg);
	float: left;
	left: -15px;
	z-index: 999;

	.circle {
		background-color: #fff;
		height: 120px;
		width: 120px;
		display: block;
		border: 5px solid #a9a9a9;
		border-radius: 100px;
		position: absolute;
		bottom: 0;
		z-index: 1;
		animation-name: circle;
		animation-duration: 1s;
		animation-timing-function: linear;
		animation-delay: 0s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		animation-play-state: running;
		-webkit-animation-name: circle;
		-webkit-animation-duration: 1s;
		-webkit-animation-timing-function: linear;
		-webkit-animation-delay: 0s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-direction: normal;
		-webkit-animation-play-state: running;
	}
	.fa {
		font-size: 42px;
		color: $primary;
		bottom: 27px;
		position: absolute;
		left: 50%;
		margin-left: -18px;
		animation-name: arrow;
		animation-duration: 1s;
		animation-timing-function: linear;
		animation-delay: 0s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		animation-play-state: running;
		-webkit-animation-name: arrow;
		-webkit-animation-duration: 1s;
		-webkit-animation-timing-function: linear;
		-webkit-animation-delay: 0s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-direction: normal;
		-webkit-animation-play-state: running;
	}
	.pulse {
		margin: 0 auto;
		border-radius: 100px;
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 0;
		background-color: transparent;
		opacity: 0;
		width: 110px;
		height: 110px;
		border: 10px solid $primarydark;
		-webkit-border-radius: 100px;
		-moz-border-radius: 100px;
		-o-border-radius: 100px;
		-ms-border-radius: 100px;
		border-radius: 100px;
		/* Giving Animation Function */
		-webkit-animation: pulse 1s linear infinite 0.3s;
		-moz-animation: pulse 1s linear infinite 0.3s;
		border-image: initial;
	}
	@keyframes arrow {
		0% {
			bottom: 0;
		}
		75% {
			bottom: 90px;
		}
		100% {
			bottom: 0;
		}
	}
	@-webkit-keyframes arrow {
		0% {
			bottom: 0;
		}
		75% {
			bottom: 90px;
		}
		100% {
			bottom: 0;
		}
	}
	@keyframes circle {
		0% {
			height: 120px;
		}
		10% {
			height: 120px;
		}
		50% {
			height: 130px;
		}
		75% {
			height: 150px;
		}
		90% {
			height: 130px;
		}
		100% {
			height: 120px;
		}
	}
	@-webkit-keyframes circle {
		0% {
			height: 120px;
		}
		10% {
			height: 120px;
		}
		50% {
			height: 130px;
		}
		75% {
			height: 150px;
		}
		90% {
			height: 130px;
		}
		100% {
			height: 120px;
		}
	}
	@-webkit-keyframes pulse {
		0% {
			-webkit-transform: scale(0);
			opacity: 0;
		}
		8% {
			-webkit-transform: scale(0);
			opacity: 0;
		}
		15% {
			-webkit-transform: scale(0.1);
			opacity: 1;
		}
		30% {
			-webkit-transform: scale(0.5);
			opacity: 1;
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.5);
		}
	}
	@-moz-keyframes pulse {
		0% {
			-webkit-transform: scale(0);
			opacity: 0;
		}
		8% {
			-webkit-transform: scale(0);
			opacity: 0;
		}
		15% {
			-webkit-transform: scale(0.1);
			opacity: 1;
		}
		30% {
			-webkit-transform: scale(0.5);
			opacity: 1;
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.5);
		}
	}
}

//koniec anim strzalki

#pass-status,
#pass-repeat-status {
	float: right;
	position: relative;
	top: -40px;
	left: -10px;
	cursor: pointer;
	z-index: 2;
	height: 0;
}

.registration {
	#pass-status,
	#pass-repeat-status {
		// left: 92.5%;
	}
}

.login-form {
	#pass-status,
	#pass-repeat-status {
		// left: 89%;
	}
}
.was-validated {
	.valid-feedback {
		font-family: NexaBold;
		font-size: 100%;
	}
}
.invalid-form {
	input,
	select,
	textarea {
		border: 0;
		border-bottom: 2px solid #fe5051 !important;
	}
	.invalid-feedback {
		position: relative;
		top: -10px;
		display: block;
		margin: 0;
		font-size: 12px;
		font-family: NexaBold;
		height: 12px;
	}

	display: block; // mazur testy
}
label {
	margin: 8px 0 2px !important;
	letter-spacing: -0.5px;
	font-size: 12px;
	font-weight: bold;
	display: block;
	width: 100%;
}
.txt-solid {
	font-size: 14px;
	font-weight: 600;
	margin-right: 10px;
}
input,
select,
.form-select {
	border-top: 0;
	border-right: 0;
	border-bottom: 2px solid #dadada !important;
	border-left: 0;
	border-radius: 0 !important;
	padding: 5px 10px;
	height: 42px;
	width: 100%;
	margin-bottom: 10px;
	// margin-bottom: 17px;
	outline: none !important;
}
input:focus,
select:focus,
.form-select:focus {
	box-shadow: none;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-color: #00d664 !important;
}

.invalid-form input:focus,
.invalid-form select:focus,
.invalid-form .form-select:focus {
	border-bottom: 2px solid #fe5051 !important;
}

.chips-container {
	background-color: #e0e0e0;
	margin: 20px -50px;
	padding: 0 35px;
	// #category-level2,
	// #category-level3 {
	// 	&:before {
	// 		background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #e0e0e0 100%) !important;
	// 	}
	// 	&:after {
	// 		background-image: linear-gradient(to top, #e0e0e0 0%, rgba(255, 255, 255, 0) 100%) !important;
	// 	}
	// }
}
.service-selector {
	align-items: flex-start;
	select {
		option {
			font-size: 16px;
			font-weight: 600;
		}
	}

	.form-select.txt-solid {
		background-color: transparent !important;
		border: 0 !important;
	}
	ul {
		list-style: none !important;
		padding: 0;
	}
	#category-level1 {
		// padding-top: 22px;
		.form-select {
			padding-left: 0;
		}
	}
	#category-level2,
	#category-level3 {
		.subcategory-list,
		.subcategory-details {
			max-height: 250px;
			width: 100%;
			overflow-y: auto;
		}
		.subcategory-details {
			padding-right: 8px;
		}
		li {
			font-size: 16px;
			padding: 10px 15px 5px;
			font-weight: 600;
			position: relative;
			&.active {
				background-color: #c8f68d;
			}
			&:hover {
				cursor: pointer;
				background-color: rgba(255, 255, 255, 0.2);
			}
			i {
				position: absolute;
				right: 14px;
				top: 10px;
				cursor: pointer;
				padding: 0px 10px 0 15px;
				font-size: 14px;
				color: $primarydark;
				&.fa-times {
					display: inline-block;
					color: #fe5051 !important;
					font-size: 14px;
					cursor: pointer;
				}
			}
		}

		// &:before {
		// 	content: '';
		// 	width: 100%;
		// 	height: 20px;
		// 	display: block;
		// 	position: relative;
		// 	bottom: 0px;
		// 	top: 20px;
		// 	background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #f2f2f2 100%);
		// 	background-size: 100% 20px;
		// 	background-repeat: no-repeat;
		// 	background-position: bottom left, bottom right, bottom left;
		// }
		// &:after {
		// 	content: '';
		// 	width: 100%;
		// 	height: 20px;
		// 	display: block;
		// 	position: absolute;
		// 	bottom: 15px;
		// 	background-image: linear-gradient(to top, #f2f2f2 0%, rgba(255, 255, 255, 0) 100%);
		// 	background-size: 100% 20px;
		// 	background-repeat: no-repeat;
		// 	background-position: bottom left, bottom right, bottom left;
		// }
	}
}
textarea {
	border-top: 0;
	border-right: 0;
	border-bottom: 2px solid #dadada;
	border-left: 0;
	padding: 5px 10px;
	height: 95px;
	width: 100%;
}

.btn {
	height: 56px;
	font-size: 18px;
	font-weight: 600;
	padding: 10px 20px;
	line-height: 2em;
	border-radius: 0;
	&.btn-primary {
		background: $primary;
		border-top: 0;
		border-right: 0;
		border-bottom: 3px solid $primarydark;
		border-left: 0;
		&:hover,
		&:focus {
			background: $primarydark !important;
		}
	}

	&.btn-white-accept {
		color: $primary;
		background-color: #fff;
		border-radius: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 3px solid $primarydark;
		border-left: 0;
		&:hover,
		&:focus {
			background: $primarydark !important;
			color: #fff;
		}
	}
	&.btn-white-reject {
		color: #d63b3b;
		background-color: #fff;
		border-radius: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 3px solid #d63b3b;
		border-left: 0;
		&:hover,
		&:focus {
			background: #d63b3b !important;
			color: #fff;
		}
	}
	&.btn-primary-dark {
		background: $primarydark;
		border-radius: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 3px solid #038a42;
		border-left: 0;
		color: #fff;
		&:hover,
		&:focus {
			background: $primarydark !important;
		}
	}
	&:hover {
		border-radius: 0 !important;
	}
	&.btn-secondary {
		border-radius: 0;
	}
}
.input-group {
	.btn {
		height: 40px;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.8em;
		img {
			margin-right: 10px;
		}
	}
}
.break {
	flex-basis: 100% !important;
	height: 0 !important;
}

.checkbox {
	height: 42px;
	display: flex;
	align-items: center;
}
.checkbox label:after {
	content: '';
	display: table;
	clear: both;
}

.checkbox .cr {
	position: relative;
	display: inline-block;
	border: 1px solid #a9a9a9;
	border-radius: 0;
	width: 20px;
	height: 20px;
	float: left;
	margin-right: 15px;
}

.checkbox .cr .cr-icon {
	position: absolute;
	font-size: 13px;
	line-height: 0;
	top: 10px;
	left: 3px;
	color: $primarydark;
}

.checkbox label input[type='checkbox'] {
	display: none;
}

.checkbox label input[type='checkbox'] + .cr > .cr-icon {
	transform: scale(3) rotateY(-20deg);
	opacity: 0;
	transition: all 0.3s ease-in;
}

.checkbox label input[type='checkbox']:checked + .cr > .cr-icon {
	transform: scale(1) rotateZ(0deg);
	opacity: 1;
}

.checkbox label input[type='checkbox']:disabled + .cr {
	opacity: 0.5;
}

.autohelp-form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	.info-bar {
		text-align: right;
		font-size: 18px;
		font-weight: 600;
		margin: 50px;
		a {
			display: inline-block;
		}
		span {
			color: $greenmuted;
			margin-right: 10px;
		}
	}
	.role-select {
		padding: 0 30px;
		h3 {
			font-weight: 600;
			span {
				color: $greenmuted;
			}
		}
		.btn-check:active + .btn-outline-primary,
		.btn-check:checked + .btn-outline-primary,
		.btn-outline-primary.active,
		.btn-outline-primary.dropdown-toggle.show,
		.btn-outline-primary:active {
			color: #fff;
			background-color: $primary;
			border-color: $primary;
		}

		.switch {
			display: block;
			position: relative;
			margin-top: -10px;
			.roleselectlabel {
				margin-left: 20px;
			}
			.switch-toggle {
				margin-left: -9999px;
				visibility: hidden;
				& ~ .switch-label {
					display: block;
					position: relative;
					left: 20px;
					cursor: pointer;
					outline: none;
					user-select: none;
					padding: 2px;
					// width: 70px;
					float: left;
					white-space: nowrap;
					width: auto;
					height: 50px;
					// transform-style: preserve-3d;
					// perspective: 500px;
				}

				& ~ .switch-label:before,
				& ~ .switch-label:after {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					color: $primarydark;
					font-size: 32px;
					text-align: center;
					line-height: 30px;
					font-family: NexaBold;
				}

				& ~ .switch-label:before {
					background-color: #fff;
					content: attr(switch-off);
					transition: transform 0.6s;
					backface-visibility: hidden;
				}

				& ~ .switch-label:after {
					background-color: #fff;
					content: attr(switch-on);
					transition: transform 0.6s;
					transform-origin: 50% 0;
					transform: rotateX(180deg);
					backface-visibility: hidden;
				}

				&:checked ~ .switch-label:before {
					transform: rotateX(100deg);
				}

				&:checked ~ .switch-label:after {
					transform: rotateX(0);
				}

				&:checked ~ #box1,
				&:not(checked) ~ #box2 {
					display: block;
				}

				&:not(checked) ~ #box1,
				&:checked ~ #box2 {
					display: none;
				}
			}
		}
		.box {
			margin: 25px -30px 0;
		}
	}
	.form-container {
		h1 {
			font-weight: 900;
			line-height: 1em;
			span {
				line-height: 1.2em;
				font-family: NexaHeavy;
				color: $primary;
			}
		}
		.roleselectlabel {
			margin-left: 20px !important;
		}
		.register-form,
		.login-form {
			// margin: 0 -20px -20px;
			padding: 30px 50px 60px;
			background-color: #f2f2f2;
			.col-sm-12 {
				// display: flex;
				// flex-wrap: wrap;
				// align-items: flex-end;
			}
			.pass-reminder {
				font-weight: 600;
				width: 100%;
				margin-top: 20px;
			}
		}
	}
}

.date-container {
	.date-from {
		margin-right: 0;
	}
}
.time-container {
	.time-from {
		margin-right: 0;
	}
}
.separator {
	margin: 0px 4px 0;
	img {
		width: 14px !important;
		height: auto;
	}
}
.col-md-2 .dual-heading {
	position: sticky;
	top: 50px;
}

/* ---------------------------------------------------
	  CONTENT STYLE
  ----------------------------------------------------- */

#content {
	width: 100%;
	perspective: 1500px;
	padding: 20px;
	transition: all 0.3s;
	max-height: calc(100vh - 120px);
	min-height: 70vh;
	align-content: flex-start;
	overflow-y: auto;
}
#topbar .menu-btn {
	width: 50px;
	height: 50px;
	background: transparent;
	border: 0;
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 20px;
	z-index: 9999;

	span {
		&:first-of-type,
		&:nth-of-type(2),
		&:last-of-type {
			transform: none;
			opacity: 1;
			margin: 5px auto;
		}
		width: 80%;
		height: 2px;
		margin: 0 auto;
		display: block;
		background: #000;
		transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
		// transition-delay: 0.2s;
	}

	&.active span {
		transform: none;
		opacity: 1;
		margin: 4px auto;

		&:first-of-type {
			transform: rotate(45deg) translate(4px, 4px);
		}

		&:nth-of-type(2) {
			opacity: 0;
		}

		&:last-of-type {
			transform: rotate(-45deg) translate(4px, -4px);
		}
	}
}

#sidebarClose.navbar-btn {
	width: 30px;
	height: 30px;
	background: transparent;
	border: 0;
	padding-right: 0;
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 10px;
	z-index: 9999;
	span {
		width: 100%;
		height: 2px;
		margin: 0 auto;
		display: block;
		background: #232323;
		transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
		// transition-delay: 0.2s;

		&:first-of-type {
			transform: rotate(45deg) translate(2px, 2px);
		}

		&:nth-of-type(2) {
			opacity: 0;
		}

		&:last-of-type {
			transform: rotate(-45deg) translate(1px, -1px);
		}
	}
}

/* ---------------------------------------------------
	  FOOTER
  ----------------------------------------------------- */
footer {
	margin-top: -60px;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	z-index: -1;
	&.container-fluid {
		padding: 0;
	}
	.left-footer {
		height: 300px;
		display: flex;
		align-items: center;
		z-index: -1 !important;
		background: url(/assets/images/footer-logo-shade.svg) no-repeat center left / contain;
		h3 {
			color: #fff;
			font-weight: 500;
			margin: 0;
			position: relative;
			left: 380px;
			span {
				color: #01d665;
			}
		}
	}
	.foot-menu {
		gap: 50px;
		display: flex;
		align-items: center;
		li {
			display: inline-block;
			font-family: 'Nexa', sans-serif;
			font-weight: 600;
			cursor: pointer;
			color: #fff;
			padding: 30px 20px;
		}
	}
	.copyrights {
		background: #232323;
		padding: 20px 40px;
		span {
			color: #555;
		}
	}
}

.dual-heading {
	font-family: NexaBold;
	letter-spacing: -1px;
	font-size: 30px;
	line-height: 1em;
	margin: 0;
	&.nobreak {
		span {
			display: inline !important;
			font-size: 30px;
		}
	}
	span {
		color: $primary;
		font-size: 26px;
		font-weight: 600;
		display: block; //podzial na dwulinie - usunac na mobile
	}
}
.service {
	background: #f2f2f2;
	cursor: pointer;
	line-height: 1.6em;
	.col-md-5 {
		border-right: 1px solid #555;
		text-align: right;
	}
	img {
		max-width: 100px;
	}

	h4,
	p span {
		color: $greenmuted;
		margin: 0;
	}
	p {
		margin: 0;
	}

	.dual-heading {
		font-size: 36px;
		span {
			font-size: 40px;
		}
	}
}
.my-requests {
	// padding-bottom: 0 !important;
	// padding-top: 0 !important;
	.dual-heading {
		border-right: 0 !important;
	}
	.green-footer {
		background-color: $primary;
		color: #fff;
		text-align: right;
		font-weight: 900;
		font-family: NexaBold;
		font-size: 16px;
		align-content: center;
		span {
			font-size: 12px;
			display: block;
			width: 100%;
		}
	}
	#filters-container {
		label {
			margin-top: 4px;
			margin-bottom: -4px;
		}
		.btn-outline-primary {
			margin-bottom: 10px !important;
		}
		.txt-solid {
			font-size: 16px;
		}
		.comments {
			font-size: 14px !important;
			margin-bottom: 10px;
		}
	}
}
.my-requests .dual-heading {
	position: static;
}

.noresults {
	img {
		filter: contrast(0.8);
		max-width: 200px;
		margin-top: 60px !important;
	}
	h5 {
		padding: 0 !important;
	}
}
.filters {
	background-color: #f2f2f2;
	height: fit-content;
	width: 100%;
	z-index: 999;
	padding: 15px 0;
	align-content: flex-start;
	label {
		margin-top: 0;
	}
	.div-cornertop {
		position: absolute;
		right: 0;
	}
	.filter-group {
		padding: 0 10px;
	}
	.filter-group .btn {
		border: 0;
		border-radius: 0;
		height: 42px;
		font-size: 16px;
		padding: 6px 15px;
		&.active {
			border-bottom: 2px solid $primarydark;
		}
	}
	.txt-solid {
		font-size: 14px;
	}
	.separator {
		margin: -1px 3px 0 !important;
	}
}
#contact-container {
	padding-left: 90px;
	img {
		margin: 10px 10px 10px 0;
	}
}
#map-container {
	padding: 0;
	background-color: #f2f2f2;
	display: block;
	transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
	.sticky-map {
		position: sticky;
		top: 10px;
		width: 100%;
		// height: 60vh;
		height: calc(100vh - 120px);
		max-width: 100%;
		object-fit: cover;
	}
}
.offer {
	position: relative;
	&.type-sos {
		border-left: 6px solid #fe5051;
	}
	&.other-is-accepted {
		opacity: 0.5;
		button {
			display: none;
		}
	}
	&.accepted {
		.subheading {
			margin-right: 15px;
			font-size: 16px;
			color: $primary;
			font-family: NexaHeavy;
			top: -6px;
			right: -6px;
		}
	}
	&.active {
		position: relative;
		border-right: 2px solid $primary;
		&::after {
			content: '';
			width: 20px;
			height: 20px;
			background-color: #fff;
			border-right: 2px solid $primary;
			border-radius: 2px;
			border-top: 2px solid $primary;
			position: absolute;
			transform: rotate(45deg);
			right: -11px;
			top: 75px;
		}
	}
	> .row {
		margin-right: 0;
		div {
			// padding-right: 0;
		}
	}
	background-color: #fff;
	label {
		width: 100%;
		display: block;
	}
	.subheading {
		font-size: 10px;
		color: #555;
		position: absolute;
		right: 15px;
		width: auto;
		top: 15px;
		padding: 0;
	}
	.txt-solid {
		margin-right: 0;
	}
	.car-container,
	.offering-container {
		align-content: flex-start;
		.txt-solid {
			margin-right: 10px;
		}
	}
	.offering-container {
		label {
			margin: 0;
		}
		.time-to,
		.time-from {
			margin-left: 15px;
		}
	}
	.mechanic {
		max-width: 60px;
		height: 130%;
		margin-right: 20px;
		object-fit: contain;
		object-position: top;
	}
	.comment {
		font-weight: 600;
		font-size: 14px;
	}
	.btn-corner {
		// right: 0 !important;
	}
	h5 {
		max-height: 60px;
		overflow: hidden;
	}
}

.offer-selected {
	border: 3px solid #00d664;
}

.offers-container {
	height: 100%;
	justify-content: flex-start;
	//   padding-top:140px;
	background-color: #f2f2f2;
	h3 {
		font-family: NexaBold;
	}
}
.users-container {
	p {
		margin-bottom: 0;
	}
	#contact-container {
		padding: 0;
		.address img {
			margin-right: 12px;
		}
	}
	background-color: #f2f2f2;
	img.mechanic,
	img.user {
		max-width: 60px;
		margin: 30px 20px 30px 0;
	}
	.user-details {
		background: #fff;
		padding-bottom: 10px !important;
	}
	.user {
		&.blocked {
			opacity: 0.5;
		}
	}
}
.profile-container,
.addform-container {
	background-color: #f2f2f2;
	// padding-inline: 0 !important;
}
.pagination {
	text-align: center;
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-top: 20px;
	.btn {
		height: 34px;
		width: 34px;
		padding: 0 10px;
	}
}
.bold {
	font-family: NexaBold;
}
.heavy {
	font-family: NexaHeavy;
}
.btn-corner {
	position: absolute;
	right: 0px;
	bottom: -15px;
	width: auto;
}
.btn-cornertop {
	position: absolute;
	right: -16px;
	top: -16px;
	width: auto;
	&.offer-sent {
		color: #206bfa;
	}
	&.offer-success {
		color: #00d664;
	}
	&.offer-reject {
		color: #fe5051;
	}
	&.offer-cancelled {
		color: #a9a9a9;
	}
}
.red {
	color: #fe5051 !important;
}
.btn-red {
	background-color: #fe5051;
	border-radius: 0;
	color: #fff;
	border-bottom: 3px solid #d63b3b;
	&:hover {
		background-color: #d63b3b;
		color: #fff;
	}
}
.content-reversed {
	display: flex;
	flex-direction: row-reverse;
}

.modal {
	.modal-body,
	.modal-header,
	.modal-footer {
		border: 0;
		border-radius: 0;
		background-color: #f2f2f2;
		padding-inline: 20px;
	}
	h4,
	h5 {
		font-family: NexaBold, Nexa, sans-serif;
		margin-bottom: 0;
	}
	.dual-heading {
		font-family: NexaHeavy, Nexa, sans-serif;
		font-size: 26px;
		text-align: center;
		span {
			font-size: 24px;
		}
	}
}
//umawianie wizyt
.planned-details {
	#wheretogo {
		opacity: 1;
		height: auto;
		&.deactive {
			height: 0;
			opacity: 0;
		}
	}
}
//moj garaz

.cars-container {
	align-content: baseline;
	.car {
		border-bottom: 1px solid #dadada;
		background-color: #fff;
		margin-bottom: 20px;
		cursor: pointer;
		.car-details {
			display: flex;
			flex-wrap: wrap;
			.car-container {
				display: flex;
				flex-wrap: wrap;
				align-content: baseline;

				.txt-solid {
					width: auto;
				}
			}
			label {
				color: $primary;
				width: 100%;
			}
		}
		.car-pic {
			margin: 10px 0;
			img {
				max-height: 160px;
			}
		}
	}
}
.car-pic,
.car-pic-side {
	.sedan {
		content: url('/assets/images/sedan.png');
	}
	.hatchback {
		content: url('/assets/images/hatchback.png');
	}
	.cabrio {
		content: url('/assets/images/cabrio.png');
	}
	.pickup {
		content: url('/assets/images/pickup.png');
	}
	.kombi {
		content: url('/assets/images/estate.png');
	}
	.van {
		content: url('/assets/images/van.png');
	}
}
#sidebar {
	.car-pic-side {
		position: fixed;
		img {
			max-height: 350px;
			min-height: 350px;
			height: 400px;
			width: auto;
			position: absolute;
			left: -114px;
			top: 30px;
			z-index: 999;
		}
	}
	.car-form {
		padding-left: 70px !important;
		padding-right: 0;
	}
}

.mobileonly {
	display: none;
}
.desktoponly {
	display: block;
}

//moje zlecenia
.myvisits-container {
	.visit-details {
		background-color: #fff;
		padding: 20px !important;
		label {
			margin: 0;
		}
		.green-footer {
			background-color: $primary;
			text-align: center;
			color: #fff;
		}
		&.history-visit {
			.green-footer {
				background-color: $greenmuted;
			}
			h3.green {
				color: #555;
			}
		}
	}
}
/* ---------------------------------------------------
	  MOBILE MENU
  ----------------------------------------------------- */
#mobileMenu {
	display: block;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 100vw;
	width: 100vw;
	height: 100vh;
	min-height: 100%;
	z-index: 1000;
	transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
	background: #05aa52;
	&::before {
		content: '';
		position: absolute;

		width: 140%;
		height: 80%;
		top: 30%;
		left: -20%;
		z-index: -1;
		transform: rotate(-30deg);
		background: url(/assets/images/footer-logo-shade.svg) center right no-repeat;
		background-size: cover;
	}

	.menu-logo-sygnet {
		margin: 30px auto;
		max-width: 30%;
	}

	.menu-links {
		font-size: 28px;
		font-weight: 600;
		list-style-type: none;
		padding: 30px 0px;
		overflow-y: auto;
		max-height: calc(100vh - 135px);
		li {
			padding: 20px 50px;
			color: #fff;
			cursor: pointer;
			text-decoration: none;
			letter-spacing: -1px;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
	}

	&.active {
		left: 0;
	}

	.menu-link-active {
		background-color: rgba(0, 0, 0, 0.1);
	}
}

/* ---------------------------------------------------
	  COMPONENTS
  ----------------------------------------------------- */

.autocomplete-wrapper {
	position: relative;

	.autocomplete-loading {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: white;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.modal-backdrop {
	opacity: 0;
	left: -20vw;
	width: 140vw;
}

.spinner {
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	border: 9px solid;
	border-color: rgba(0, 214, 100, 0.34);
	border-right-color: $primary;
	animation: spinner 1s infinite linear;

	&.dark-spinner {
		border-color: rgba(3, 138, 66, 0.2);
		border-right-color: $primarydark;
	}
	&.spinnercenter {
		position: absolute;
		left: calc(50% - 0.6rem);
	}
}

.pagination {
	.disabled {
		pointer-events: none;
		opacity: 0.65;
		a {
			color: #555;
		}
	}
}

@keyframes spinner {
	to {
		transform: rotate(1turn);
	}
}

.car-reset-button {
	&:hover {
		cursor: pointer;
	}
}

.button-loading {
	float: right;
	opacity: 0;
}

body {
	&.hide-scroll .wrapper {
		overflow: visible;
	}
}

.modal-open .modal {
	overflow: hidden !important;
}

.map-embed {
	width: 100%;
}

/* ---------------------------------------------------
	  MEDIAQUERIES
  ----------------------------------------------------- */
@media (max-width: 1000px) {
	h1 {
		font-size: 36px;
	}
	.mobileonly {
		display: block;
	}
	.desktoponly {
		display: none;
	}
	.btn-submit {
		width: 100%;
		&.mt-5 {
			margin-top: 20px !important;
		}
	}

	.autohelp-toast {
		width: 90vw;
		right: 5vw;
	}
	.btn-mobile {
		width: 100%;
	}
	#topbar {
		.topbar-left {
			justify-content: center;
		}
	}
	.separator {
		margin: 2px 2px 0;
		img {
			width: 14px !important;
			height: auto;
		}
	}
	.was-validated {
		.valid-feedback {
			color: #fff;
			font-family: NexaBold;
			text-align: center;
			text-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
		}
	}
	.col-sm-0 {
		display: none;
	}
	.menu-links {
		font-size: 18px;
		padding: 30px 0px;
		li {
			padding: 10px 35px !important;
		}
	}
	.w30,
	.w40,
	.w50,
	.w60,
	.w70 {
		min-width: 100%;
		max-width: 100%;
		.row {
			margin: 0 !important;
			padding: 0 !important;
		}
	}
	.checkbox {
		label {
			margin-bottom: 10px !important;
		}
	}
	.wrapper {
		width: 100% !important;
		margin: 0 0 70px 0;
		perspective: none !important;
		#mobile-filters {
			display: flex;
		}
		#topbar {
			max-height: 90px;
			position: fixed;
			top: 0;
			z-index: 999;
			background-color: #fff;
			#menuCollapse {
				display: block !important;
				&.active {
					span {
						background-color: #fff;
					}
				}
			}
			.top-logo {
				// margin: 30px 120px 30px 30px;
				height: auto !important;
				img {
					content: url('/assets/images/logo-horizontal.svg') !important;
					min-width: 100%;
					width: 100% !important;
					height: auto !important;
					min-height: 10px;
					display: block;
				}
			}
		}
		.content-reversed {
			display: flex;
			flex-direction: row;
			max-width: 100vw;
		}
		#map-container {
			height: calc(100vh - 230px);
			// margin-left: -100vw;
			margin-top: 0 !important;
			padding: 0 !important;
			position: fixed !important;
			top: 220px;
			left: -100vw;
			right: 0;
			opacity: 0;
			z-index: 999;
			&.show {
				height: calc(100vh - 220px);
				// margin-left:0;
				left: 0;
				opacity: 1;
			}
			.sticky-map {
				// height: calc(100vh - 230px);
				// height: 100%;
				height: calc(100vh - 220px);
				// height: calc(100vh - 120px);
				margin: 0;
				padding: 0;
			}
		}
		#content {
			max-height: none;

			.btn-corner {
				position: static;
				width: 100%;
			}
			.myborder-right {
				border: 0;
			}
			.myborder-left {
				border: 0;
			}
			.txt-solid {
				margin-right: 5px;
			}
			select.txt-solid {
				margin-right: 3px;
			}
			.dual-heading {
				text-align: center;
				font-size: 24px;
				font-weight: 900;
				border: 0 !important;
				padding-right: 0 !important;
				padding-left: 0 !important;
				span {
					display: inline-block;
					font-size: 24px;
					margin-left: 6px;
				}
			}
			.filters {
				margin-top: 90px;
				input {
					margin-top: 10px;
					margin-bottom: 10px;
				}
				.filter-group {
					// padding-top:20px !important;
				}
				.subheading,
				.green-footer {
					display: none !important;
				}
				#filters-container {
					.px-1 {
						padding: 0 !important;
					}
				}
			}

			.offer {
				// flex-direction: column-reverse;
				padding: 0 !important;
				h3 {
					margin-top: 20px;
					text-align: center;
				}
				.subheading {
					text-align: center;
					position: static !important;
					width: 100% !important;
				}
				> .row {
					margin-right: 0;
					> div {
						padding-right: 15px !important;
					}
				}
				.btn-cornertop {
					&.offer-success,
					&.offer-reject,
					&.offer-sent {
						width: 100%;
						position: static;
						margin-right: 0 !important;
					}
				}
				.btn-corner {
					margin-top: 10px !important;
				}
				&.type-sos {
					border-left: 0;
					border-top: 6px solid #fe5051;
					h3.green {
						color: #fe5051;
					}
				}
				&.active {
					border: 0;
					&::after {
						display: none;
					}
				}
				.time-from {
					margin-left: 20px;
				}
				&.accepted {
					.subheading {
						font-size: 22px;
						margin-block: 10px;
					}
				}
				#contact-container {
					padding-left: 12px;
				}
			}

			.service-selector {
				flex-direction: column;
				margin-bottom: 15px;
			}
		}
		#category-level1 {
			.txt-solid {
				margin-left: 0;
			}
		}
		#sidebar {
			// min-height: auto;
			max-height: auto;
			background: #01d665;
			position: fixed;
			right: -100vw;
			top: 0;
			color: #fff;
			transform-origin: bottom right;
			transform: none;
			z-index: 999;
			label {
				width: auto !important;
				flex: 1 1 100%;
			}
			// max-height: 100vh;
			max-height: 100%;
			min-height: 100%;
			&.active {
				position: fixed;
				transform: none;
				height: auto;
				min-height: auto;
				margin: 0;
				top: 0;
				right: 0;
			}
			&.with-filters {
				// min-height: 100vh !important;
				min-height: 100% !important;
			}
			.sidebar-content {
				.details {
					padding: 0;
					.date-container {
						.txt-solid {
							margin-right: 0;
						}
					}
				}
				.lower-form {
					padding: 26px 26px 26px 26px;
					.btn-primary {
						margin: 4px;
						width: calc(100% - 8px);
					}
				}

				//
				.service-selector {
					width: 100%;

					#category-level2,
					#category-level3 {
						background-color: rgba(0, 0, 0, 0.1);
						margin-left: -30px;
						.subcategory-list,
						.subcategory-details {
							max-height: 350px;
						}
						// &:before {
						// 	background-image: linear-gradient(
						// 		to top,
						// 		rgba(255, 255, 255, 0) 0%,
						// 		$primarydark 100%
						// 	) !important;
						// }
						// &:after {
						// 	background-image: linear-gradient(
						// 		to top,
						// 		$primarydark 0%,
						// 		rgba(255, 255, 255, 0) 100%
						// 	) !important;
						// }
						li {
							padding: 10px 30px 5px 50px;
							color: #232323 !important;
							&.active {
								background-color: #c8f68d;
							}
							&:hover {
								cursor: pointer;
								background-color: rgba(255, 255, 255, 0.2);
								i {
									display: block;
									&.fa-times {
										color: #fe5051 !important;
									}
									&.fa-plus {
										color: #fff !important;
									}
								}
							}
						}
						.subcategory-details {
							li {
								padding-left: 80px;
							}
						}
					}
				}
				.lower-form {
					background-color: $primarydark;
					z-index: 999;
					// max-height:40vh;
					// overflow-y: auto;
					padding: 30px;
					margin: 30px -30px -30px -30px;

					.text-white {
						margin: 0;
					}
					button {
						margin-top: 20px;
					}
				}

				.chips-container {
					background-color: transparent;
					margin: 20px -55px;
					hr {
						display: none;
					}
					#category-level2,
					#category-level3 {
						li {
							color: #232323 !important;
						}
						// &:before {
						// 	background-image: linear-gradient(
						// 		to top,
						// 		rgba(255, 255, 255, 0) 0%,
						// 		$primary 100%
						// 	) !important;
						// }
						// &:after {
						// 	background-image: linear-gradient(
						// 		to top,
						// 		$primary 0%,
						// 		rgba(255, 255, 255, 0) 100%
						// 	) !important;
						// }
						.subcategory-details {
							li {
								padding-left: 80px;
							}
						}
					}
				}
				.col-xl-12 {
					padding: 0;
				}
			}
		}
		#details-container {
			&.show {
				display: flex !important;
			}
			.comments {
				margin-bottom: 20px;
			}

			.limited {
				height: auto;
			}
		}
		.car-container {
			// padding-inline: 0;
		}
		.users-container {
			.user {
				margin-bottom: 20px;
			}

			.user-details {
				.contact-container {
					padding-left: 0;
				}
			}
		}
		#sidebarCollapse {
			span {
				&:first-of-type,
				&:nth-of-type(2),
				&:last-of-type {
					transform: none;
					opacity: 1;
					margin: 5px auto;
				}
			}

			&.active span {
				margin: 0 auto;

				&:first-of-type {
					transform: rotate(45deg) translate(2px, 2px);
				}

				&:nth-of-type(2) {
					opacity: 0;
				}

				&:last-of-type {
					transform: rotate(-45deg) translate(1px, -1px);
				}
			}
		}
	}
	footer {
		margin-top: 0;
		.left-footer {
			h3 {
				margin: 0 auto;
				position: static;
			}
		}
		.foot-menu {
			display: none;
		}
		.copyrights {
			padding: 20px;
			span {
				display: none;
			}
		}
	}

	.modal {
		.modal-body,
		.modal-header,
		.modal-footer {
			padding: 20px;
		}
		.map-container {
			display: none;
		}
		.modal-header h3 {
			text-align: center;
			width: 100%;
		}
		.btn-close {
			position: absolute;
			right: 20px;
		}
		.modal-footer {
			display: flex;
			justify-content: space-between;
			button {
				padding: 10px 20px;
			}
			&.text-left {
				display: flex;
				justify-content: center;
			}
		}
	}
	.myvisits-container {
		padding: 0;
	}
	// 2.0 main
	.services-container {
		background-color: transparent;
		margin-top: 100px;
		width: 100% !important;
		margin: 120px 0 50px;
		.service {
			padding-bottom: 30px;
			background-color: rgba(242, 242, 242, 0.8);
			backdrop-filter: blur(6px);
			> div {
				// justify-content: center !important;
			}
			h4 {
				font-size: 20px;
				font-weight: 700;
			}
			p {
				font-weight: 700;
			}
		}
	}
	//1.0 login register
	.bg-car {
		background: #fff url(/assets/images/spoiler-bg.jpg) no-repeat bottom right / cover !important;
		position: fixed;
		z-index: -1;
		overflow: visible;
		bottom: 0;
		height: 50vh;
		h1 {
			display: none;
		}
	}

	.invalid-form {
		input,
		select {
			border: 0;
			margin-bottom: 0;
			border-bottom: 2px solid #fe5051 !important;
		}
		input[type='password'] {
			margin-bottom: 10px !important;
		}
		.invalid-feedback {
			position: static;
			top: -10px;
			display: block;
			margin: 0;
			font-size: 100%;
			height: auto;
		}
	}
	.login-cover {
		height: 100%;
		position: fixed;
		background-size: 150% !important;
		overflow: hidden;
		&.register {
			background-size: 100% !important;
			position: static;
			height: auto;
		}
		.top-logo img {
			height: 100px;
			margin-top: 30px;
		}
	}
	.left-footer {
		display: none !important;
	}
	.autohelp-form {
		height: auto;
		min-height: 100vh;
		justify-content: normal;
		h1 {
			font-size: 24px !important;
			color: #fff;
			margin-top: 150px;
			padding-bottom: 0 !important;
			margin-bottom: 0;
			text-align: right;
			padding-right: 25px !important;
			span {
				color: #fff !important;
			}
		}
		.info-bar {
			text-align: center;
			margin-top: 0;
			order: 2;
			z-index: 99;
			a,
			span {
				color: #fff !important;
			}
		}

		.login-form {
			padding: 10px !important;
			background-color: transparent !important;
			.was-validated {
				.valid-feedback {
					background-color: $primarydark;
					width: 100%;
					text-align: center;
					border-radius: 20px;
					padding: 5px 0;
					margin: 10px 0;
				}
			}
			label {
				color: #fff !important;
			}
			input {
				// margin-bottom: 0 !important;
			}
		}
		.pass-reminder {
			margin-bottom: 30px;
			text-align: center;
			color: #fff;
		}
		.form-container {
			z-index: 999;
			max-width: 500px;
			margin: 0 auto;

			height: 100%;
			align-content: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.arrcontainer {
			// left: 0;
			top: 5px;
		}
		&.registration {
			background-color: #f2f2f2;
			.form-container {
				flex-direction: row !important;
			}
			.register-form {
				padding: 0 !important;
				margin-top: 0 !important;
			}
			.info-bar {
				a,
				span {
					color: #000 !important;
				}
			}
			label {
				color: #000 !important;
			}
		}
		.role-select {
			#category-level2,
			#category-level3 {
				margin: 0px -15px !important;
			}
		}
	}
	.chips-container {
		background-color: #f2f2f2;
		margin: 20px -50px;
		padding: 0 35px;
		// #category-level2,
		// #category-level3 {
		// 	&:before {
		// 		background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #f2f2f2 100%) !important;
		// 	}
		// 	&:after {
		// 		background-image: linear-gradient(to top, #f2f2f2 0%, rgba(255, 255, 255, 0) 100%) !important;
		// 	}
		// }
		.subcategory-details {
			padding-right: 0px !important;
		}
	}
	.service-selector {
		flex-direction: column;
		#category-level1 {
			div.txt-solid {
				text-align: left;
			}
		}
		#category-level2,
		#category-level3 {
			background: #eeeeee;
			margin: 0px -15px;
			width: 100vw;
			// box-shadow: inset 0 20px 10px rgba(0, 0, 0, 0.05);
			&:before,
			&:after {
				display: none;
			}
			ul {
				margin: 0;
			}
			li {
				padding-left: 40px;
			}
		}
		#category-level3 {
			background-color: #e9e9e9;
			li {
				padding-left: 80px;
			}
		}
	}
	// profile

	.profile-container,
	.addform-container {
		margin-top: 100px;
	}
	.profile-container {
		padding-inline: 20px;
		.checkbox {
			padding-inline: 0;
		}
	}

	h4.bold {
		text-align: center;
		margin-top: 0px !important;
		padding-top: 10px !important;
	}
	h2.heavy {
		text-align: center;
	}
	hr {
		margin: 20px 0 !important;
	}

	button[type='submit'] {
		margin: 20px 0;
		width: 100%;
	}
	.roleselectlabel {
		text-align: center;
		position: relative;
		margin-left: 0 !important;
		top: 15px;
	}
	.switch-label:before,
	.switch-label:after {
		font-size: 20px !important;
	}

	//dodawanie zgloszen
	.planned-details {
		.btn {
			width: 100%;
		}
		.btn.button-right {
			width: auto !important;
		}
	}
	.planned-container {
		padding: 0;
	}
	//garaz
	#sidebar {
		.car-form {
			padding: 0 !important;
			.col-md-12 {
				padding: 0 !important;
			}
			button {
				width: 100%;
			}
		}
	}
	.cars-container {
		padding: 0 !important;
		.col-sm-6,
		.col-sm-8 {
			padding: 0 !important;
		}
		.car-details {
		}
		.car-pic {
			display: none;
		}
		button {
			width: 100%;
			&.mt-5 {
				margin-top: 15px !important;
			}
		}
	}
	.addform {
		.service-selector {
			margin-left: -15px;
		}
	}
	//moje oferty
	.my-requests {
		.dual-heading,
		.subheading,
		.green-footer {
			text-align: center !important;
		}
	}
	.offers-container {
		margin-top: 0 !important;
		.dual-heading {
			margin-top: 0;
		}
		.offer {
			h5 {
				margin-top: 20px;
				text-align: center;
			}
			img.float-left {
				display: none !important;
			}
			#contact-container {
				padding: 0;
			}
			.col-lg-8.myborder-left {
				padding: 0;
			}
		}
	}
	.accept-reject-btns {
		margin-top: 20px !important;
		.col-sm-6 {
			padding: 0 !important;
			margin-top: 10px;
		}
	}

	.manufacturer-select {
		padding: 0;
	}
}

/////
$mobile-width: 1000px;
$mid-tablet-width: 1000px;
$tablet-width: 1400px;
$desktop-width: 1900px;

// @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}){  // 1400 -> 1920
@media (max-width: #{$desktop-width}) {
	// -> 1920
	.form-container {
		h1 {
			padding-block: 10px !important;
		}
	}
}
// @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}){ // 768 -> 1400
@media (max-width: #{$tablet-width}) {
	// -> 1400
	h1 {
		font-size: 38px;
	}
	.wrapper {
		width: 95%;
	}
	.col-sm-0 {
		display: none;
	}

	.top-logo {
		img {
			height: 30px;
		}
	}
	label {
		letter-spacing: 0 !important;
	}
	#sidebar {
		.service-selector {
			flex-direction: column;
			#category-level1 {
				div.txt-solid {
					text-align: left;
				}
			}
			#category-level2,
			#category-level3 {
				// margin: 0px -25px;
				// width: 100vw;
				box-shadow: inset 0 20px 10px rgba(0, 0, 0, 0.05);
				&:before,
				&:after {
					display: none;
				}
				ul {
					margin: 0;
				}
				li {
					padding-left: 60px;
				}
			}
			#category-level3 {
				background-color: rgba(0, 0, 0, 0.15);
				li {
					padding-left: 80px;
				}
			}
		}
	}

	.invalid-form {
		.invalid-feedback {
			margin-bottom: 20px;
		}
	}
	.switch {
		.roleselectlabel {
		}
		.switch-toggle {
			& ~ .switch-label {
			}

			& ~ .switch-label:before,
			& ~ .switch-label:after {
				font-size: 24px !important;
				line-height: 30px;
			}
		}
	}
	//main
	.service {
		background: #f2f2f2;
		cursor: pointer;
		line-height: 1.6em;
		.col-md-4:first-of-type {
			border: 0;
		}
		.col-sm-6:nth-of-type(2) {
			// border-left: 1px solid #555;
		}
		img {
			display: none;
		}
	}
	//mojgaraz
	.car-pic-side {
		display: none;
	}
	#sidebar .car-form {
		padding-left: 0 !important;
	}
	//mojezgloszenia
	.profile-container {
		.col-md-3 {
			// padding: 20px !important;
		}
	}
	.visit-details {
		.flex-md-row {
			border-bottom: 1px solid #dadada;
			margin-bottom: 10px;
			flex-direction: column;
		}
		.col-sm-12 {
			padding-inline: 0 !important;
		}
		.col-md-12 {
			padding-inline: 0 !important;
		}
		.myborder-left {
			border: 0 !important;
		}
		.myborder-right {
			border-right: 0;
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid #dadada;
		}
	}
	//tablicazgloszen-uslugi
	.offers-container {
		.offer {
			// padding-right: 0 !important;
			.myborder-left {
				// border: 0;
			}
		}
	}
	//lista admina
}

@media (max-width: #{$mid-tablet-width}) {
	.switch {
		.roleselectlabel {
		}
		.switch-toggle {
			& ~ .switch-label {
			}

			& ~ .switch-label:before,
			& ~ .switch-label:after {
				font-size: 20px !important;
				line-height: 30px;
			}
		}
	}
	footer {
		h3 {
			display: none;
		}
	}
}

@media (min-width: #{$mobile-width}) {
	#topbar {
		.menu-btn {
			top: 32px;
		}
	}

	.menu-links {
		font-size: 20px !important;
		li {
			padding: 15px 30px !important;
		}
	}
	.services-container {
		overflow-y: auto;
		height: 100%;
	}
	.arrcontainer {
		top: -5px;
	}
	#mobileMenu {
		max-width: 30%;
		&.active {
			left: 70%;
		}
	}
	.planned-details {
		.justify-content-center {
			justify-content: flex-start !important;
		}
	}
}
/* avatar && cropper*/
.avatar {
	width: 175px;
	height: 175px;
	border-radius: 50%;
	border: 2px solid black;
}
.avatar:hover {
	opacity: 0.7;
	cursor: pointer;
}
.avatar-col {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.modal-cropper {
	padding: 24px;
	position: absolute;
	top: calc(50% - 48px);
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border-radius: 6px;
	z-index: 4;
	text-align: center;
}

.modal-cropper__shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: inline;
	z-index: 3;
	background-color: rgba(0, 0, 0, 0.4);
}

.avatar-dropzone {
	cursor: pointer;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed #aaaaaa;
	border-radius: 6px;
	background-color: #fcfcfc;
	padding: 24px;
	max-width: 450px;
	margin: 20px auto 20px auto;
	& p {
		margin: 0;
	}
}

.avatar-save-button,
.avatar-cancel-button {
	margin: 0 auto 20px auto;
	text-align: center;
	max-width: 200px;
}

.avatar-cropper {
	max-height: 450px;
	min-height: 300px;
	max-width: 550px;
	min-width: 350px;
	margin-bottom: 20px;
}

.user-rating-wrapper {
	margin-bottom: 40px;
}

.user-rating-wrapper svg {
	width: 34px;
}
